
import { defineComponent, ref } from "vue";


import axios from "axios";
import Swal from "sweetalert2";


export default defineComponent({
  props: ['cliente','contatos','enderecos'],
  name: "add-cliente-modal",

  data() {
    return {
      
    }
  },
  mounted() {
	   this.$nextTick(function () {
			
	})

  },

  methods:{

  novoContato(){

    this.contatos.push({
      
    })

  },  

   novoEndereco(){

    this.enderecos.push({
      
    })

  },  

  excluirContato(id, index){

    if(this.contatos.length ==1){
      Swal.fire(
            'Contato!',
            'É necessário ter pelo menos um contato cadastrado.',
            'error'
          )
    }else{


      Swal.fire({
      title: 'Excluir?',
      text: "Tem certeza que deseja excluir este registro!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, Excluir!'
    }).then((result) => {
      if (result.isConfirmed) {


        axios.delete(process.env.VUE_APP_API_URL + '/contatos/'+id)
       .then(({ data }) => {   
          Swal.fire(
            'Deletado!',
            'Seu registro foi deletado.',
            'success'
          ).then(()=>{
            this.contatos.splice(index,1)
          })



        })
        
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          console.log(response)
        });


      }

       
      
    })


    }


    
  },
  
  excluirEndereco(id, index){

    if(this.contatos.length ==1){
      Swal.fire(
            'Contato!',
            'É necessário ter pelo menos um endereço cadastrado.',
            'error'
          )
    }else{


      Swal.fire({
      title: 'Excluir?',
      text: "Tem certeza que deseja excluir este registro!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, Excluir!'
    }).then((result) => {
      if (result.isConfirmed) {


        axios.delete(process.env.VUE_APP_API_URL + '/enderecos/'+id)
       .then(({ data }) => {   
          Swal.fire(
            'Deletado!',
            'Seu registro foi deletado.',
            'success'
          ).then(()=>{
            this.enderecos.splice(index,1)
          })



        })
        
        .catch(({ response }) => {
          //this.context.commit(Mutations.SET_ERROR, response.data.errors);
          console.log(response)
        });


      }

       
      
    })


    }


  },

    
	salvarCliente(){

    this.cliente.contatos = this.contatos
    this.cliente.enderecos = this.enderecos


    if(this.cliente.id){
      axios.put(process.env.VUE_APP_API_URL + '/clientes/'+this.cliente.id, this.cliente)
       .then(({ data }) => {   
        this.$emit('atualizarCliente')
      }).then(()=>{
          Swal.fire(
            'Atualização!',
            'Registro foi Atualizado.',
            'success'
          )
      })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        console.log(response)
      });
    }else{


      var ender = this.enderecos[0].endereco
      var contat = this.contatos[0].contato

      

      if( typeof ender == 'undefined' || typeof contat == 'undefined'){
         
        
          Swal.fire(
            'Falta Dados!',
            'Necessário cadastrar pelo menos um contato e um enderço do cliente!.',
            'error'
          )

      }else{


        axios.post(process.env.VUE_APP_API_URL + '/clientes', this.cliente)
       .then(({ data }) => { 
        this.$emit('atualizarCliente')
      }).then(()=>{
          Swal.fire(
            'Cadastro!',
            'Registro Incluído.',
            'success'
          )
      })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_ERROR, response.data.errors);
        console.log(response)
      });





      }



      


    }

    }

		
  },


  components: {
   
  },




  setup() {
   
  
    return {
      
    
    };
  },
});
